import React, { Component } from 'react';
import ContentWrapper from "../../../Layout/ContentWrapper";
import axios from "../../../../services/axios";
import { Container, Card, CardHeader, CardBody, CardTitle, Button } from "reactstrap";
import ReactDatatable from '@ashvin27/react-datatable';
import Moment from 'moment'

class ComplaintsLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            operators: []
        }
    }

    componentDidMount() {
        axios.get("/complaints")
            .then(res => {
                const operators = res.data;
                this.setState({ operators })
                console.log(operators);
            })
    }

    formatDate = (date) => {
        return Moment(date).format('lll')
      }


    columns = [
        {
            key: "id",
            text: "ID",
            cell: (record, index) => {
                return index + 1;
            }
        },
        {
            key: "phoneNumber",
            text: "PHONE NUMBER"
        },
        {
            key: "message",
            text: "COMPLAINT"
        },
        {
            key: "status",
            text: "STATUS"
        },
        {
            key: "dateCreated",
            text: "DATE RECEIVED",
            cell: (record, index) => {
                return (this.formatDate(record.createdAt))
              }
        },
        {
            key: "shortCode",
            text: "SHORT CODE"
        },
        {
            key: "smsId",
            text: "SMS ID"
        },
         {
            key: "smscId",
            text: "SMSC ID"
        }
    ]

    config = {
        page_size: 10,
        length_menu: [10, 25, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        filename: "Contact List",
        button: {

        },
        language: {
            loading_text: "Please be patient while data loads..."
        }
    }


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div className="mr-auto flex-row">
                        Complaints
    <small>List of all Complaints's received.</small>
                    </div>
                    <div className="flex-row">
                    
                    </div>
                </div>
                <Container fluid>
                    <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody>
                            <ReactDatatable
                                extraButtons={this.extraButtons}
                                config={this.config}
                                records={this.state.operators}
                                columns={this.columns}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </ContentWrapper>
        );
    }
}

export default ComplaintsLog;
