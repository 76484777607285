import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import Datatable from "../../../Common/Datatable";
import axios from "../../../../services/axios";
import { Container, Card, CardHeader, CardBody, CardTitle, Button } from "reactstrap";
import $ from "jquery";
import { AuthService } from '../../../../services';
import { Redirect } from 'react-router-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Moment from "moment";
import { Fragment } from "react";

import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
import withReactContent from 'sweetalert2-react-content';

class UsersManagement extends Component {
  state = {
    usersList: [],
    loading: true,
    roles: [],
    errMsg: ""
  };

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" });
    }

    this.GetAllUser();
    let user_roles;

    let role = [];

    if (localStorage.getItem('user_object') != null && JSON.parse(localStorage.getItem('user_object')).roles != undefined) {
      user_roles = JSON.parse(localStorage.getItem('user_object')).roles;
      role = user_roles.map((role) => {
        return role.type;
      });
    }

    console.log(role);

    this.setState({ roles: role });
  }

  AddNewUser = () => {
    return this.props.history.push('/admin-add-admin-user');
  };
  AddNormalUser = () => {
    return this.props.history.push('/admin-add-user');
  };


  ViewAccountExpiration = () => {
    return this.props.history.push('/admin-account-expiration');
  };


  GetAllUser = () => {
    axios.get("/users")
      .then(res => {
        console.log(res.data);
        const response = res.data;
        this.setState({ loading: false });
        this.setState({ usersList: response });
      }).catch(err => {
        this.setState({ errMsg: "Not Authorized" });
      });
  };


  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  };
  DisableUser = (row) => {
    axios.put("/users/deactivate/" + row.id)
      .then(res => {
        const response = res.data;
        this.GetAllUser();
      });
  };

  EditUser = (row) => {
    return this.props.history.push('/admin-manage-edit-user/' + row.id, row);
  };

  formatDate = (date) => {
    return Moment(date).format('lll');
  };


  EnableUser = (row) => {
    axios.put("/users/activate/" + row.id)
      .then(res => {
        this.GetAllUser();
      });

  };

  deleteCurrentUser(row) {
    axios.delete("/users/" + row.id).then(res => {
      this.showSweetAlert('success', 'User deleted Sucessfully');
      this.GetAllUser();
    });
  }

  showSweetAlert(icon, title) {
    return MySwal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: "",
      showConfirmButton: false,
      timer: 2000
    });
  }

  columns = [
    {
      key: "id",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index += 1;
      }
    },
    {
      key: "name",
      text: "FULL NAME"
    },

    {
      key: "email",
      text: "EMAIL"
    },
    {
      key: "roles",
      text: "ROLE",
      cell: (record, index) => {
        console.log(record);
        let roles;

        try {
          roles = record.roles.map(role => {
            return role.type;
          });
          return roles.toString();
        } catch (error) {

        }


      }
    },
    {
      key: "isActive",
      text: "STATUS",
      sortable: true,
      cell: (record, index) => {
        if (record.isActive == 1) {
          return (
            <span className="badge badge-success">{"Active"}</span>
          );
        }
        if (record.isActive != 1) {
          return (<span style={{ backgroundColor: "#a1a1a1", color: "white" }} className="badge">{"Inactive"}</span>);
        }


      }
    },
    {
      key: "dateCreated",
      text: "DATE CREATED",
      sortable: true,
      cell: (record, index) => {
        return (this.formatDate(record.dateCreated));
      }
    },
    {
      key: "isActive",
      text: "ACTION",
      cell: (record, index) => {
        if (this.state.roles.includes('ADMIN')) {
          if (record.isActive == 1) {
            return (
              <Fragment>
                <span className="btn badge-success mr-2 px-4" onClick={() => this.EditUser(record)}> <i className="icon-pencil mr-2"  ></i>Edit</span>
                <span className="btn badge-danger px-4 mr-2" onClick={() => this.DisableUser(record)}> <i className="fa fa-power-off mr-2"></i>Disable</span>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <span className="btn badge-success mr-2 px-4" onClick={() => this.EditUser(record)}> <i className="icon-pencil mr-2"  ></i>Edit</span>
                <span className="btn badge-primary  px-4 mr-2" onClick={() => this.EnableUser(record)}> <i className="fa fa-power-off mr-2"></i>Enable</span>
              </Fragment>
            );
          }
        }



      }
    }
  ];

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {

    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  };


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            Manage User
            <small>Showing all users.</small>
          </div>
          <div className="flex-row">
            {/* <Button onClick={this.ViewAccountExpiration} style={this.AddActionButtonStyle} className="btn-pill-right mr-2">
              <i className="fa fa-clock mr-2"></i>
              Manage Account Expiration</Button> */}
            {/* <Button onClick={this.AddNormalUser} style={this.AddActionButtonStyle} className="btn-pill-right mr-2">
              <i className="fa fa-user mr-2"></i>
              Add User</Button> */}
              {this.state.errMsg.length > 0 ? '' : <Button onClick={this.AddNewUser} style={this.AddActionButtonStyle} className="btn-pill-right">
              <i className="fa fa-users mr-2"></i>
              Add New User</Button> }
            
          </div>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
            </CardHeader>
            <CardBody>
              {/* <span className="btn badge-danger mt-1"> <i className="icon-trash mr-2"></i>Delete</span> <br/>  */}
              {this.state.errMsg.length > 0 ? <h1>{this.state.errMsg}</h1> : <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.usersList}
                columns={this.columns}
                loading={this.state.loading}
              />}


            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default UsersManagement;
