import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import axios from "../../../../services/axios";
import {
    Container, Card, CardHeader, CardBody, CardTitle, Button, ModalHeader, Modal,
    ModalBody,
    ModalFooter,
    FormGroup
} from "reactstrap";
import $ from "jquery";
import ReactDatatable from '@ashvin27/react-datatable';
import { Fragment } from "react";
import Swal from "sweetalert2"
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class ManageBranch extends Component {
    state = {
        branches: [],
        region: {
            name: "",
            branchCode: "",
            regionId: ""
        },
        regions: [],
        editedRegion: {},
        AddMode: true,
        loading:true
    };

    componentDidMount() {
        this.GetBranches();
        this.GetRegions();
    }

    GetRegions() {
        axios.get("/regions")
            .then(res => {
                this.setState({loading:false})
                this.setState({ regions: res.data })
            })
    }

    handleSubmit = event => {
        event.preventDefault();
        this.toggleModal();

        if (this.state.AddMode) {
            console.log(this.state.region);
            axios.post("/branches", this.state.region).then(res => {
                console.log(res);
                this.GetBranches();
                this.showSweetAlert('Added Branch Successfully');
            })

        } else {
            axios.put("/branches", this.state.editedRegion).then(res => {
                console.log(res);
                this.GetBranches();
                this.showSweetAlert('Updated Branch Successfully');
            })
        }
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    GetBranches() {
        axios.get("/branches")
            .then(res => {
                const branches = res.data;
                this.setState({ branches })
                console.log(branches);
            })
    }

    DeleteRegion(id) {
        axios.delete("/branches/" + id)
            .then(res => {
                const response = res.data;
                const branches = this.state.branches.filter((item) => {
                    return item.id !== id;
                });
                this.setState({ branches })
            })
    }

    showSweetAlert(message) {
        return MySwal.fire({
            position: 'center',
            icon: 'success',
            title: message,
            text: "",
            showConfirmButton: false,
            timer: 1500
        })
    }


    EditRegion(row) {
        const regionId=row.region==null?0:row.region.id;
        const editedRegion = {
            id: row.id,
            name: row.name,
            branchCode: row.branchCode,
            regionId: regionId
        }
        this.setState({ editedRegion: editedRegion })
        this.setState({ AddMode: false })
        this.toggleModal()
    }



    AddActionButtonStyle = {
        color: 'white',
        background: "#003366"
    }

    handleChange = event => {
        if (this.state.AddMode) {
            this.setState({
                region: Object.assign({},
                    this.state.region, { [event.target.name]: event.target.value })
            })
        } else {
            this.setState({
                editedRegion: Object.assign({}, this.state.editedRegion,
                    { [event.target.name]: event.target.value })
            })
        }
    }

    handleRegionChange = event => {
        const regionId = [event.target.value];
        const region = this.state.regions.find(item => item.id == regionId);
        this.setState({
            region: Object.assign({},
                this.state.region, { regionId: region.id })
        })
        
    }

    setAddOperatorMode = () => {
        this.setState({ AddMode: true })
        this.toggleModal();
    }
    columns = [
        {
            key: "id",
            text: "ID",
            cell: (record, index) => {
                return index + 1;
            }
        },
        {
            key: "name",
            text: "BRANCH"
        },
        {
            key: "branchCode",
            text: "BRANCH CODE"
        },
        {
            key: "region",
            text: "REGION",
            cell:(record,index)=>{
                if(record.region !=null){
                    return (record.region.name)
                }else{
                    return ("N/A")
                }
            }
        },
        {
            key: "id",
            text: "ACTION",
            cell: (record, index) => {
                return (
                    <Fragment>
                        <span className="btn badge-success mr-2 px-4" onClick={() => this.EditRegion(record)}> <i className="icon-pencil mr-2"  ></i>Edit</span>
                        <span className="btn badge-danger  px-4" onClick={() => this.DeleteRegion(record.id)}> <i className="fa fa-trash mr-2"></i>Delete</span>
                    </Fragment>
                )
            }
        }
    ]

    config = {
        page_size: 10,
        length_menu: [10, 25, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        filename: "Contact List",
        button: {

        },
        language: {
            loading_text: "Please be patient while data loads..."
        }
    }


    render() {
        return (
            <ContentWrapper>

                <div className="content-heading">
                    <div className="mr-auto flex-row">
                        Manage Branches
                        <small>Showing all branches.</small>
                    </div>
                    <div className="flex-row">
                        <Button onClick={this.setAddOperatorMode} style={this.AddActionButtonStyle} className="btn-pill-right mr-2">Add New Branch</Button>

                        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                            <ModalHeader toggle={this.toggleModal}>{this.state.AddMode ? "Add Branch" : "Edit Branch"}</ModalHeader>
                            <form onSubmit={this.handleSubmit}>
                                <ModalBody>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect2">Select Region : </label>
                                        <select className="form-control" id="exampleFormControlSelect2"
                                        name="regionId"
                                         onChange={this.handleChange} 
                                        value={this.state.AddMode ? this.state.regionId : this.state.editedRegion.regionId}>
                                            <option>Select Region</option>
                                            {this.state.regions.map(row => (
                                                <option key={row.id} value={row.id} >
                                                    {row.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group px-md-2 px-1">
                                        <label>Branch Name :</label>
                                        <input className="form-control" name="name" onChange={this.handleChange}
                                            value={this.state.AddMode ? this.state.name : this.state.editedRegion.name}
                                            required ></input>
                                    </div>
                                    <div className="form-group px-md-2 px-1">
                                        <label>Branch Code :</label>
                                        <input className="form-control" name="branchCode" onChange={this.handleChange}
                                            value={this.state.AddMode ? this.state.branchCode : this.state.editedRegion.branchCode}
                                            required ></input>
                                    </div>
                                    {/* <div className="form-group px-md-2 px-1">
                                        <label>Region :</label>
                                        <input className="form-control" name="regionId" onChange={this.handleChange}
                                            value={ this.state.AddMode? this.state.regionId :this.state.editedRegion.regionId}
                                            required ></input>
                                    </div> */}

                                </ModalBody>
                                <ModalFooter>
                                    <button className="btn btn-sm  mr-3 px-4" style={this.AddActionButtonStyle}>
                                        Save
                                    </button>

                                </ModalFooter>
                            </form>
                        </Modal>
                    </div>
                </div>



                <Container fluid>
                    <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody>

                            <ReactDatatable
                                extraButtons={this.extraButtons}
                                config={this.config}
                                records={this.state.branches}
                                columns={this.columns}
                                loading={this.state.loading}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </ContentWrapper>
        );
    }
}

export default ManageBranch;
