const Menu = [
  
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'icon-speedometer',
        translate: 'sidebar.nav.DASHBOARD'
    },
    {
        name: 'Transactions',
        icon: 'fa fa-briefcase',
        path: '/transactions'
    },
    {
        name: 'Manage Users',
        icon: 'fa fa-users',
        path: '/manage-users'
    },
    // {
    //     name: 'Account Management',
    //     icon: 'fa fa-user',
    //     path: '/admin-manage-account'
    // },  
];


export default Menu;