import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import {
    Container,
    Card,
    CardBody,
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row, Col, Input, CardFooter, CustomInput
} from "reactstrap";
import $ from "jquery";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
import withReactContent from 'sweetalert2-react-content';
import FormValidator from '../../../Common/FormValidator';
import axios from '../../../../services/axios';

class EditUser extends Component {


    state = {
        formRegister: {
            id: 0,
            fullname: "",
            status: false,
            email: "",
            roleName: "",
        },
        selectedRole: 0,
        passwordReset: {
            userId: "",
            newPassword: "",
            newPassword2: ""
        },
        user: {},
        selectedRoleList: [],
        roles: [],
        rolesList: [],
        passwordModal: false,
        id: 0,
        displayAdminFields: true,

    };
    componentDidMount() {
        const { state } = this.props.history.location;
        console.log(state);
        if (state == undefined) {
            return this.props.history.push('/admin-customers-list/');
        }


        axios.get('/roles')
            .then(res => {
                const response = res.data;
                console.log(response);
                this.setState({ rolesList: response });
            });

        this.setState({
            formRegister: Object.assign({}, this.state.formRegister, {
                id: state.id,
            }),
        });


        axios.get("/users/" + state.id)
            .then(res => {
                console.log(res.data);
                const user = res.data;
                console.log(user);
                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        fullname: user.name,
                    }),
                });
                this.setState({
                    formRegister: Object.assign(
                        {}, this.state.formRegister, {
                        email: user.email
                    }
                    )
                }),
                    this.setState({
                        formRegister: Object.assign({}, this.state.formRegister, {
                            status: user.status,
                        }),
                    });
                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        roleName: user.roleName,
                    }),
                });


                const roles = res.data.roles;
                this.setState({ roles });
                this.setState({ user });
            }).catch(error => {
                console.log("Action Ancountered an Error");
            });
    }
    validateOnChange = event => {
        const input = event.target;
        const form = input.form;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        if (result != null) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    [input.name]: value,
                    errors: {
                        ...this.state[form.name].errors,
                        [input.name]: result
                    }
                }
            });
        }
    };


    ViewUserPage = () => {
        return this.props.history.push("/manage-users");
    };


    handleSmsTemplateChange = event => {
        const templateId = event.target.value;
        const template = this.state.rolesList.find(item => item.id == templateId);
        this.setState({ role: template.name });
        this.setState({ description: template.description });
        this.setState({ roleId: template.id });
    };


    handleSubmit = event => {
        this.toggleModal();
        event.preventDefault();

        console.log(event.target.value);
        const roleId = this.state.roleId;
        const role = this.state.rolesList.find(item => item.id == roleId);
        const found = this.state.roles.find((row) => row.id == roleId);

        if (found == undefined) {
            const selectedRoleList = [...this.state.roles, role];
            this.setState({ roles: selectedRoleList });
        }
        console.log(this.state.roleId);
        console.log(this.state.role);
        console.log(this.state.description);

    };

    showSweetAlert(icon, title) {
        return MySwal.fire({
            position: 'center',
            icon: icon,
            title: title,
            text: "",
            showConfirmButton: false,
            timer: 2000
        });
    }

    handlePasswordReset = e => {
        e.preventDefault();
        this.togglePasswordModal();
        console.log("am here password reset");
        console.log(this.state.passwordReset.newPassword);
        console.log(this.state.passwordReset.newPassword2);
        console.log(this.state.user.id);
        const userId = this.state.user.id;

        const data = { ...this.state.passwordReset, userId: userId };
        console.log(data);
        axios.post("/users/admin-password-reset", data).then(res => {
            console.log(res);
            console.log(res.data);
            if (res.data.message != "") {
                this.showSweetAlert('success', 'Password Updated Sucessfully');
                this.ViewUserPage();
            } else {
                this.showSweetAlert('info', 'Password Mismatch');
            }
        });
    };

    deleteCurrentUser() {
        const userId = this.state.id;
        axios.post("users/delete/" + userId).then(res => {
            console.log(res);
            this.showSweetAlert('success', 'User deleted Sucessfully');
            this.ViewUserPage();
        });
    }

    handleChange = event => {
        event.preventDefault();
        console.log(event.target.value);
        this.setState({ formRegister: { ...this.state.formRegister, [event.target.name]: event.target.value } })
    };

    onChangedSelectInput = (event) => {
        event.preventDefault();
        this.setState({ selectedRole: event.target.value });
        console.log(this.state.selectedRole);
    }


    onSubmit = e => {
        e.preventDefault();
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

        const { errors, hasError } = FormValidator.bulkValidate(inputs);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        console.log(hasError ? 'Form has errors. Check!' : 'Form Submitted!');

        if (!hasError) {

            // const roles = [];
            // this.state.roles.forEach(item => {
            //     roles.push(item.id);
            // });

            const data = {
                "id": this.state.formRegister.id,
                "name": this.state.formRegister.fullname,
                "email": this.state.formRegister.email,
                "roleName": this.state.formRegister.roleName
            };
            console.log(data);
            axios.put("users/" + data.id, data).then(res => {
                console.log(res);
                console.log(res.data);
                axios.post("/roles/assign", {
                    "userId": data.id,
                    "roleId": +`${this.state.selectedRole}`
                }).then(resp => {
                    console.log(resp);
                })
                this.ViewUserPage();
            });
        }
    };

    hasError = (formName, inputName, method) => {
        return this.state[formName] &&
            this.state[formName].errors &&
            this.state[formName].errors[inputName] &&
            this.state[formName].errors[inputName][method];
    };


    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    togglePasswordModal = () => {
        this.setState({
            passwordModal: !this.state.passwordModal
        });
    };

    ViewAllAdminUsers = () => {
        return this.props.history.push('/manage-users');
    };

    AddActionButtonStyle = {
        color: 'white',
        background: "#003366"
    };
    DeleteActionButtonStyle = {
        color: 'white',
        background: "#ec2121"
    };

    DeleteUserRole = (id) => {
        const role = this.state.roles.find(item => item.id == id);
        const selectedRoleList = this.state.roles.filter(row => row.id != role.id);
        this.setState({ roles: selectedRoleList });

    };

    render() {
        let index = 0;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div className="mr-auto flex-row">
                        Edit User
                        <small>Updating user details.</small>
                    </div>
                    <div className="flex-row">

                        <Button onClick={this.ViewAllAdminUsers} style={this.AddActionButtonStyle} className="btn-pill-right mr-2">View All Users</Button>

                        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                            <ModalHeader toggle={this.toggleModal}>Add Role : </ModalHeader>
                            <form onSubmit={this.handleSubmit}>
                                <ModalBody>

                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Select Role: </label>
                                        <select className="form-control" id="exampleFormControlSelect1" name="role"
                                            onChange={this.handleSmsTemplateChange}
                                            value={this.handleChange}
                                        >
                                            <option >Select role</option>
                                            {this.state.rolesList.map(row => (
                                                <option key={row.id} value={row.id}>
                                                    {row.name}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                    <FormGroup>
                                        <label>Description :</label>
                                        <textarea col="5" className="form-control" name="description" value={this.state.description}
                                            type="text" disabled></textarea>
                                    </FormGroup>


                                </ModalBody>
                                <ModalFooter>
                                    <button className="btn btn-sm btn-success mr-3  px-5" type="submit">
                                        Add Role
                                    </button>
                                </ModalFooter>
                            </form>
                        </Modal>
                    </div>
                </div>
                <Container fluid>
                    <div className="row">
                        <div style={{ margin: "auto" }} className="col-md-10">
                            <form onSubmit={this.onSubmit} name="formRegister" action="">
                                <Card className="card-default">
                                    <CardBody>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Full Name :</label>
                                                    <Input type="text"
                                                        name="fullname"
                                                        invalid={this.hasError('formRegister', 'fullname', 'required')}
                                                        onChange={this.validateOnChange}
                                                        data-validate='["required"]'
                                                        value={this.state.formRegister.fullname}

                                                    />


                                                    <span className="invalid-feedback">Field is required</span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Email Address </label>
                                                    <Input type="email"
                                                        name="email"
                                                        invalid={this.hasError('formRegister', 'email', 'required') || this.hasError('formRegister', 'email', 'email')}
                                                        onChange={this.validateOnChange}
                                                        data-validate='["required", "email"]'
                                                        value={this.state.formRegister.email} />
                                                    {this.hasError('formRegister', 'email', 'email') && <span className="invalid-feedback">Field must be valid email</span>}
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Status </label>

                                                    <select className="form-control"
                                                        invalid={this.hasError('formRegister', 'status', 'required')}
                                                        onChange={this.validateOnChange}
                                                        data-validate='["required"]'
                                                        value={this.state.formRegister.status} name="status">
                                                        <option disabled={true}>Select role</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>Inactive</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlSelect2">Select Role : </label>
                                                    <select className="form-control" id="exampleFormControlSelect1" name="role"
                                                        onChange={this.onChangedSelectInput}
                                                        value={this.state.selectedRole}
                                                    >
                                                        <option disabled key={0} value={0}>Select role</option>
                                                        {this.state.rolesList.map(row => (
                                                            <option key={row.id} value={row.id}>
                                                                {row.name}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlSelect2">Select Role Name : </label>
                                                    <select className="form-control" id="exampleFormControlSelect1" name="roleName"
                                                        onChange={this.onChangedSelectInput}
                                                        value={this.state.formRegister.roleName}
                                                    >
                                                        <option>Select role Name</option>
                                                        <option disabled selected key={0} value="ADMIN">ADMIN</option>
                                                        {/* <option key={1} value="USER">USER</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <div className="d-flex align-items-center">
                                            <div className="ml-auto">
                                                <button className="btn btn-danger px-5 mr-2" onClick={this.ViewAllAdminUsers}>Cancel</button>
                                                <button type="submit" style={this.AddActionButtonStyle} className="btn btn-primary px-5">Save</button>
                                            </div>
                                        </div>
                                    </CardFooter>


                                </Card>

                            </form>
                        </div>
                    </div>
                </Container>
            </ContentWrapper>
        );
    }
}

export default EditUser;
