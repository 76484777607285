import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import PageLoader from './components/Common/PageLoader';

//Authorized uses Base 
import Base from './components/Layout/Base';
//Unauthorized uses BasePage
import BasePage from './components/Layout/BasePage';



import MobileOperator from './components/Pages/Admin/Settings/MobileOperator';
import UsersManagement from './components/Pages/Admin/UserManagement/UsersList';



import EditAdminUser from './components/Pages/Admin/UserManagement/EditAdminUser';
import ManageEmail from './components/Pages/Admin/Settings/ManageEmail';
import ManageRole from './components/Pages/Admin/Settings/ManageRole';
import ManageSmsc from './components/Pages/Admin/Settings/ManageSmsc';
import UserSelfManagement from './components/Pages/Admin/UserManagement/UserSelfManage';
import EditSelfManage from './components/Pages/Admin/UserManagement/EditSelfManage';
import UserAccountExpiration from './components/Pages/Admin/UserManagement/UserAccountExpiration';
import ManageRegion from './components/Pages/Admin/Settings/ManageRegion';
import ManageBranch from './components/Pages/Admin/Settings/ManageBranch';
import ComplaintsLog from './components/Pages/Admin/Settings/ComplaintsLog';
import AddAdminUser from './components/Pages/Admin/UserManagement/AddAdminUser';
import ManageDepartment from './components/Pages/Admin/Settings/ManageDepartment';
import AddSystemUser from './components/Pages/Admin/UserManagement/AddSystemUser';
import Transactions from './components/Pages/Admin/Transactions/Transactions';
import EditTransaction from './components/Pages/Admin/Transactions/EditTransaction';
import DisparchTransaction from './components/Pages/Admin/Transactions/DispatchTransaction';




const waitFor = Tag => props => <Tag {...props} />;

const Dashboard = lazy(() => import('./components/Pages/Admin/Dashboard/Dashboard'));
const Login = lazy(() => import('./components/Auth/Login'));



const listofPages = [
    '/login',
    '/register',
    '/otp-token',
    '/'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(Login)} />
                        <Route path="/" component={waitFor(Login)}/>
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else {
        return (
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route path="/dashboard" component={waitFor(Dashboard)} />

                                    <Route path="/admin-mobile-operators" component={waitFor(MobileOperator)} />
                                    <Route path="/admin-complaints-log" component={waitFor(ComplaintsLog)} />                                   
                                    <Route path="/transactions" component={waitFor(Transactions)}/>
                                    <Route path="/edit-transaction/:id" component={waitFor(EditTransaction)}/>
                                    <Route path="/dispatch-transaction/:id" component={waitFor(DisparchTransaction)} ></Route>
                                    <Route path="/admin-manage-edit-user/:id" component={waitFor(EditAdminUser)}/>
                                    <Route path="/admin-add-admin-user" component={waitFor(AddAdminUser)} />
                                    <Route path="/admin-add-user" component={waitFor(AddSystemUser)} />
                                    <Route path="/manage-users" component={waitFor(UsersManagement)} />
                                    <Route path="/admin-manage-account" component={waitFor(UserSelfManagement)} />
                                    <Route path="/admin-self-edit/:id" component={waitFor(EditSelfManage)}/>
                                    <Route path="/admin-manage-regions" component={waitFor(ManageRegion)} />
                                    <Route path="/admin-manage-branches" component={waitFor(ManageBranch)} />
                                    <Route path="/admin-manage-department" component={waitFor(ManageDepartment)} />
                                    <Route path="/admin-manage-mail" component={waitFor(ManageEmail)}/>
                                    <Route path="/admin-manage-roles" component={waitFor(ManageRole)}/>
                                    <Route path="/admin-manage-smsc" component={waitFor(ManageSmsc)}/>
                                    <Route path="/admin-account-expiration" component={waitFor(UserAccountExpiration)}/>
                                

                                    {/* <Redirect to="/dashboard" /> */}
                                    <Redirect  to="/" />
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);