import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import { Link, Redirect } from 'react-router-dom';
import {
    Container,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroup,
    InputGroupAddon,
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row, Col, Input, CardFooter, CustomInput
} from "reactstrap";
import { MoonLoader } from 'react-spinners';
import $ from "jquery";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
import withReactContent from 'sweetalert2-react-content';
import FormValidator from '../../../Common/FormValidator';
import { SuccessAlert, InfoAlert } from '../../../Common/AppAlerts';
import axios from '../../../../services/axios';

class EditTransaction extends Component {


    state = {
        formRegister: {
            id: 0,
            msisdn: "",
            amount: 0,
            txn_no: "",
            receipt: "",
            paymentStatus: "",
            invoice: "",
            paymentType: "",
            remark: "",
            foreverpaystatus: ""
        },
        roles: [],
        isLoading: false
    };
    componentDidMount() {
        const { state } = this.props.history.location;
        console.log(state);
        if (state == undefined) {
            return this.props.history.push('/transactions/');
        }

        let user_roles = JSON.parse(localStorage.getItem('user_object')).roles;
        console.log(user_roles);

        this.setState({ roles: user_roles });

        axios.get("/bills/list_payment/" + state.id)
            .then(res => {
                const payment = res.data;
                console.log(payment);
                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        amount: payment.amount,
                    }),
                });
                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        txn_no: payment.paymentId,
                    }),
                });
                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        msisdn: payment.msisdn,
                    }),
                });
                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        receipt: payment.paymentReceipt,
                    }),
                });
                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        paymentStatus: payment.paymentStatus,
                    }),
                });
                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        invoice: payment.invoice,
                    }),
                });

                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        id: payment.id,
                    }),
                });

                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        paymentType: payment.paymentType,
                    }),
                });

                this.setState({
                    formRegister: Object.assign({}, this.state.formRegister, {
                        foreverpaystatus: payment.foreverPayStatus
                    })
                });
            });
    }
    validateOnChange = event => {
        const input = event.target;
        const form = input.form;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        if (result != null) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    [input.name]: value,
                    errors: {
                        ...this.state[form.name].errors,
                        [input.name]: result
                    }
                }
            });
        }
    };


    ViewTransactionsPage = () => {
        return this.props.history.push("/transactions");
    };


    handleSubmit = event => {
        this.toggleModal();
        event.preventDefault();


    };

    showSweetAlert(icon, title) {
        return MySwal.fire({
            position: 'center',
            icon: icon,
            title: title,
            text: "",
            showConfirmButton: false,
            timer: 2000
        });
    }


    handleChange = event => {
        event.preventDefault();
        this.setState({ formRegister: { ...this.state.formRegister, [event.target.name]: event.target.value } });
        console.log(this.state.formRegister.foreverpaystatus);
    };


    onSubmit = e => {
        e.preventDefault();
        this.setState({isLoading: true})
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
        console.log(inputs)
        const { errors, hasError } = FormValidator.bulkValidate(inputs);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (!hasError) {
            const data = {
                "id": this.state.formRegister.id,
                "invoice": this.state.formRegister.invoice,
                "remark": this.state.formRegister.remark,
                "foreverpaystatus": this.state.formRegister.foreverpaystatus,
                "userfk": String(JSON.parse(localStorage.getItem('user_object')).id)
            };

            console.log(data);
            axios.put("/bills/update_order/", data).then(res => {
                console.log(res.data);
                this.setState({isLoading: false})
                SuccessAlert(`Payment ${this.state.formRegister.txn_no} was successfully updated`);
                setTimeout(() => {
                    this.ViewAllTransactions();
                }, 2000);

            }).catch(err => {
                console.log(err);
                setTimeout(() => {
                    InfoAlert(`Failed to update payment ${this.state.formRegister.txn_no}`);
                }, 2000);
            });
        }
    };


    onEnterPress = (e) => {
        e.preventDefault()
        if (e.keyCode === 13) {
            this.btn.click();
          }
    }

    hasError = (formName, inputName, method) => {
        return this.state[formName] &&
            this.state[formName].errors &&
            this.state[formName].errors[inputName] &&
            this.state[formName].errors[inputName][method];
    };


    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };


    ViewAllTransactions = () => {
        return this.props.history.push('/transactions');
    };

    AddActionButtonStyle = {
        color: 'white',
        background: "#003366"
    };
    DeleteActionButtonStyle = {
        color: 'white',
        background: "#ec2121"
    };

    override = `
        position: absolute;
        display: block;
        margin: 0 auto;
        border-color: red;
        z-index: 99;
        left: 45%;
        top: 50%;
    `;

    onEnterPress = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            this.form_ref.submit();
        }
    }




    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div className="mr-auto flex-row">
                        Edit Transaction
                        <small>Updating Transaction details</small>
                    </div>
                    <div className="flex-row">
                        <Button onClick={this.ViewAllTransactions} style={this.AddActionButtonStyle} className="btn-pill-right mr-2">View All Transactions</Button>

                        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                            <ModalHeader toggle={this.toggleModal}>Add Role : </ModalHeader>
                            <form onSubmit={this.handleSubmit}>
                                <ModalBody>

                                    <div className="form-group">

                                    </div>
                                    <FormGroup>
                                        <label>Description :</label>
                                        <textarea col="5" className="form-control" name="description" value={this.state.description}
                                            type="text" disabled></textarea>
                                    </FormGroup>
                                </ModalBody>
                            </form>
                        </Modal>
                    </div>
                </div>
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.onSubmit} ref={node => this.refs = node} name="formRegister" action="">

                                <Card className="card-default">
                                        <MoonLoader loading={this.state.isLoading} css={this.override} />
                                    <CardBody>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Mobile *:</label>
                                                    <Input type="text" disabled={true}
                                                        name="amount"
                                                        invalid={this.hasError('formRegister', 'amount')}

                                                        value={this.state.formRegister.msisdn}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Amount</label>
                                                    <Input type="email" disabled={true}
                                                        name="email"

                                                        // onChange={this.validateOnChange}

                                                        value={this.state.formRegister.amount} />

                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Transaction Number :</label>
                                                    <Input type="number" disabled={true}
                                                        name="phoneNumber"
                                                        // onChange={this.validateOnChange}

                                                        value={this.state.formRegister.txn_no}
                                                        data-param="10" />

                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Receipt Number :</label>
                                                    <Input type="text" disabled={true}
                                                        name="receipt"
                                                        value={this.state.formRegister.receipt}
                                                        data-param="10" />

                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Payment Status :</label>

                                                    <select name="foreverpaystatus" value={this.state.formRegister.foreverpaystatus} onChange={this.handleChange} className="form-control">
                                                        <option value="">Select payment status</option>

                                                        <option value="USED" >USED</option>
                                                    </select>

                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Invoice Number :</label>
                                                    <Input required type="text"
                                                        name="invoice"
                                                        invalid={this.hasError('formRegister', 'invoice', 'minlen')}
                                                        onChange={this.validateOnChange}

                                                        value={this.state.formRegister.invoice}
                                                        data-param="10" />

                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Payment Type :</label>

                                                    <input className="form-control" name="paymentType" type="text" disabled value={this.state.formRegister.paymentType} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Description :</label>
                                                    {/* <input onChange={this.validateOnChange} className="form-control" name="description" value={this.state.formRegister.remark} />  */}
                                                    
                                                    <Input type="text"
                                                        name="remark"
                                                        onChange={this.validateOnChange}
                                                        value={this.state.formRegister.remark} />
                                                </div>
                                            </div>
                                        </div>

                                    </CardBody>
                                    <CardFooter>
                                        <div className="d-flex align-items-center">
                                            <div className="ml-auto">
                                                <span className="btn btn-danger px-5 mr-2" onClick={this.ViewAllTransactions}>Cancel</span>
                                                <button type="submit" style={this.AddActionButtonStyle} className="btn btn-primary px-5" value="Save" >Save</button>
                                            </div>
                                        </div>
                                    </CardFooter>


                                </Card>

                            </form>
                        </div>
                    </div>
                </Container>
            </ContentWrapper>
        );
    }
}

export default EditTransaction;