import React, { Component, Fragment } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";

import { Container, Card, CardHeader, CardBody, CardTitle, Button, Row } from "reactstrap";
import $ from "jquery";
import Moment from 'moment';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from "../../../../services/axios";
import api from '../../../../services/axios';
import { AuthService } from '../../../../services';
import { Redirect } from 'react-router-dom';

class Transactions extends Component {
  state = {
    transactionList: [],
    loading: true,
    roles: [],
    formSearch: {
      keyword: "",
      startDate: "",
      endDate: "",
      network: "",
      paymentType: "",
      paymentStatus: "",
      limit: 300
    },
    trackId: null
  };

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" });
    }
    this.getBillLists();

    let user_roles;

    let role = [];

    if (localStorage.getItem('user_object') != null && JSON.parse(localStorage.getItem('user_object')).roles != undefined) {
      user_roles = JSON.parse(localStorage.getItem('user_object')).roles;
      role = user_roles.map((role) => {
        return role.type;
      });
    }

    this.setState({ roles: role });
  }

  getBillLists = () => {
    let end_date = new Date();
    end_date.setDate(end_date.getDate() + 1);

    let start_date = new Date();
    start_date.setDate(start_date.getDate() );

    console.log(start_date.toLocaleDateString('en-CA'));

    let data = {
      startDate: start_date.toLocaleDateString('en-CA'),
      endDate: end_date.toLocaleDateString('en-CA'),
      limit: this.state.formSearch.limit,
      network: this.state.formSearch.network,
      paymentStatus: this.state.formSearch.paymentStatus,
      paymentType: this.state.formSearch.paymentType,

    };
    console.log(this.state.roles);
    // api.post("/bills/search", data)
    //   .then(res => {
    //     const response = res.data;
    //     this.setState({ loading: false });
    //     this.setState({ transactionList: response });
    //   }).catch(err => {
    //     console.log(err);
    //   });

    this.searchPayments(data)
  };

  searchPayments = (data) => {
    axios.post("/bills/search", data)
      .then(res => {
        let response = res.data;
        this.setState({ loading: false });
        this.setState({ transactionList: response.payments });
        this.setState({ trackId: response.trackId });
      }).catch(err => {
        console.log(err);
      });
  };

  getSearchResult = (e) => {
    e.preventDefault();
    let date = new Date(this.state.formSearch.endDate);
    date.setDate(date.getDate() + 1);

    console.log(date.toLocaleDateString('en-CA'));

    let data = {
      endDate: this.state.formSearch.endDate,
      keyword: this.state.formSearch.keyword,
      limit: +`${this.state.formSearch.limit}`,
      network: this.state.formSearch.network,
      paymentStatus: this.state.formSearch.paymentStatus,
      paymentType: this.state.formSearch.paymentType,
      startDate: this.state.formSearch.startDate
    };
    console.log(data);
    this.searchPayments(data);
  };

  handleOnchange = (e) => {
    e.preventDefault();
    this.setState({ formSearch: { ...this.state.formSearch, [e.target.name]: e.target.value } });
  };

  handleLoadMoreSearch = (e) => {
    const payload = { ...this.state.formSearch, "trackId": this.state.trackId };
    axios.post('/bills/search', payload).then(res => {
      let response = res.data;
      this.setState({ loading: false });
      this.setState({ transactionList: response.payments });
      this.setState({ trackId: response.trackId });
    });
  };




  formatDate = (date) => {
    return Moment(date).format('lll');
  };

  ViewCustomerDetails = (row) => {
    return this.props.history.push('/admin-complaint-details/' + row.id, row);
  };

  EditPayment = (row) => {
    return this.props.history.push('/edit-transaction/' + row.id, row);
  };

  DisparchPayment = (row) => {
    return this.props.history.push('/dispatch-transaction/' + row.id, row);
  };



  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  };

  config = {
    page_size: 30,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {

    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  };




  columns = [
    {
      key: "index",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index + 1;
      }
    },
    {
      key: 'createdAt',
      text: 'date',
      cell: (record, index) => {
        let d = new Date(record.createdAt).toLocaleString('en-US', { hour12: true });
        return d;
      }
    },
    {
      key: 'paymentId',
      text: 'Txn Id',
    },
    {
      key: 'msisdn',
      text: 'MSISDN',

    },
    {
      key: 'network',
      text: 'Network',
    },
    {
      key: 'amount',
      text: 'Amount',

    },
    {
      key: 'referenceNumber',
      text: 'Reference',
    },
    {
      key: 'paymentReceipt',
      text: 'Receipt',
    },
    {
      key: 'invoice',
      text: 'Invoice',

    }, {
      key: 'paymentStatus',
      text: 'Status'

    },
    {
      key: 'paymentType',
      text: 'Payment Type'
    },
    {
      key: "foreverPayStatus",
      text: "Payment Status",
    },
    {
      key: 'action',
      text: 'Action',
      cell: (record, index) => {
        if (this.state.roles.includes('ADMIN')) {
          return (
            <>
              <Fragment>
                <button className="btn badge-warning text-white mr-2 px-4" onClick={() => this.EditPayment(record)}><b>Update</b></button>
              </Fragment>
            </>
          );
        }
        if (this.state.roles.includes('DISPARCHER')) {
          return (
            <>
              <Fragment>
                <button className="btn badge-success mr-2 px-4" onClick={() => this.DisparchPayment(record)}><b>Dispatch</b></button>
              </Fragment>
            </>
          );
        }

        if (this.state.roles.includes('Super Admin')) {
          return;
        }
      }
    }
  ];


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            Transactions
            <small>Showing all transactions.</small>
          </div>
          <div className="flex-row">
          </div>
        </div>
        <div>
        <form method="POST" className="my-3" onSubmit={(e) => this.getSearchResult(e)} >
                  {/* <div className="col-1 mr-2 form-group">
                    <label>Keyword</label>
                    <input onChange={this.handleOnchange} type="text" className="form-control" name="keyword" placeholder="Keyword" id="keyword" />
                  </div> */}
                  <h4>Search Options</h4>
                  <div className="form-row">
                  <div className="col">
                    <label>From</label>
                    <input onChange={this.handleOnchange} type="date" className="form-control" name="startDate" placeholder="From" id="start_date" />
                  </div>
                  <div className="col">
                    <label>To</label>
                    <input onChange={this.handleOnchange} type="date" className="form-control" name="endDate" placeholder="From" id="end_date" />
                  </div>
                  <div className="col">
                    <label>network</label>
                    <select onChange={this.handleOnchange} className="form-control" name="network" >
                      <option value="">All</option>
                      <option value="airtel" >Airtel</option>
                      <option value="halotel" >Halotel</option>
                      <option value="tigo">Tigo</option>
                      <option value="vodacom">Vodacom</option>
                    </select>
                  </div>

                  <div className="col">
                    <label>Type</label>
                    <select onChange={this.handleOnchange} className="form-control" name="paymentType" >
                      <option value="">All</option>
                      <option value="topup" >TOPUP</option>
                      <option value="normal" >NORMAL</option>
                    </select>
                  </div>

                  <div className="col">
                    <label>Status</label>
                    <select onChange={this.handleOnchange} className="form-control" name="paymentStatus"  >
                      <option value="" >All</option>
                      <option value="success" >Success</option>
                      <option value="failed" >Failed</option>
                      <option value="pending">Pending</option>
                      <option value="pending-retry">Pending Retry</option>
                    </select>
                  </div>

                  <div className="col">
                    <label>Records</label>
                    <select onChange={this.handleOnchange} value={this.state.formSearch.limit} className="form-control" name="limit" >
                      <option value="10000">All</option>
                      <option value="100">100</option>
                      <option value="300" >300</option>
                      <option value="500" >500</option>
                      <option value="1000" >1000</option>
                    </select>
                  </div>

                  <div className="col d-flex align-items-end">
                    <input type="submit" className="btn mr-2 btn-primary" value="Search" />
                  </div>
                  </div>

                </form>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
              <Row>
    
              </Row>
              <Row className="d-flex mr-1 justify-content-end">
                <button className="btn btn-success" onClick={this.handleLoadMoreSearch} >Load more</button>
              </Row>
            </CardHeader>
            <CardBody>
              <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.transactionList}
                columns={this.columns}
                loading={this.state.loading}
              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default Transactions;
